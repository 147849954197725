import { ClockCounterClockwise, ExclamationMark } from '@phosphor-icons/react';
import classNames from 'classnames';
import { InfoIcon, PremiumPlanIcon } from 'common/assets';
import {
  DataPlanTypeEnum,
  ProductItemGradeEnum,
  ProductScaleEnum,
  SimPlanStatusEnum,
} from 'common/constants/enum';
import {
  CustomizationUnit,
  applyCustomization,
} from 'common/repositories/customization';
import colors from 'common/styles/colors';
import { TypographyVariantType } from 'common/styles/typography';
import {
  formatDate,
  formatDateDifference,
  formatTimezone,
  getDateLocale,
} from 'common/utils/date';
import { toLoosePrecision } from 'common/utils/number';
import { capitalize, string2money, string2number } from 'common/utils/string';
import ESIMComponent from 'components/common/esim-component';
import Tooltip, {
  interceptClickEvents,
  TooltipIcon,
} from 'components/common/tooltip';
import Text, { TextProps } from 'components/elements/text/base';
import { differenceInDays, endOfDay, format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

import { EsimPlanComponentStyles } from './styles.css';

interface EsimPlanPriceComponentProps {
  qty?: number;
  total: number;
  sellPrice: number | undefined;
  discountedPrice: number | undefined;
  currency: string;

  customization?: {
    root?: CustomizationUnit<React.ComponentProps<'div'>>;
    priceLayout?: CustomizationUnit<React.ComponentProps<'div'>>;

    qty?: CustomizationUnit<TextProps>;
    sellPrice?: CustomizationUnit<TextProps>;
    discountedPrice?: CustomizationUnit<TextProps>;

    total?: CustomizationUnit<TextProps>;
    BeforePrice?: React.ReactNode;
  };
}

export function EsimPlanPriceComponent(props: EsimPlanPriceComponentProps) {
  const { qty, total, sellPrice, discountedPrice, currency, customization } =
    props;

  /* Orders page puts the order-related details next to the ESIMComponent, while Subscription page puts it at the bottom. As annoying as the conditions for the layout can be, this can be generalized into two positions: 'right' or 'below' in the main scaffold component. */
  const isDiscount =
    sellPrice != null &&
    discountedPrice != null &&
    sellPrice !== discountedPrice;

  return (
    <div
      {...applyCustomization(
        {
          className: structuralStyles.flexbox({
            direction: 'row',
            align: 'start',
            justify: 'between',
            wrap: true,
          }),
        },
        [customization?.root],
      )}
    >
      <div
        {...applyCustomization(
          {
            className: structuralStyles.flexbox({
              direction: 'row',
              fill: true,
              wrap: true,
              columnGap: 8,
            }),
          },
          [customization?.priceLayout],
        )}
      >
        {customization?.BeforePrice}
        {sellPrice != null && (
          <div className={structuralStyles.flexbox({ columnGap: 4 })}>
            {qty && (
              <Text
                {...applyCustomization(
                  {
                    span: true,
                    textColor: 'foregroundPrimary',
                    textVariant: 'body2Semibold',
                  },
                  [customization?.qty],
                )}
              >
                {`${qty}x `}
              </Text>
            )}
            <Text
              {...applyCustomization(
                {
                  span: true,
                  wrap: false,
                  textVariant: 'body2Regular',
                  td: isDiscount ? 'line-through' : 'none',
                  textColor: 'foregroundTertiary',
                },
                [customization?.sellPrice],
              )}
            >
              {`${currency} ${string2money(sellPrice)}`}
            </Text>
          </div>
        )}
        {isDiscount && discountedPrice != null && (
          <Text
            {...applyCustomization(
              {
                mr: 10,
                wrap: false,
                textVariant: 'body2Semibold',
              },
              [customization?.discountedPrice],
            )}
          >{`${currency} ${string2money(discountedPrice)}`}</Text>
        )}
      </div>
      <Text
        {...applyCustomization(
          {
            textVariant: 'body1Semibold',
            wrap: false,
          },
          [customization?.total],
        )}
      >{`IDR ${string2money(total)}`}</Text>
    </div>
  );
}

interface EsimPlanSummaryLabelProps {
  validityDays: number | null;
  quotaInGb: number | null;
  dataType: DataPlanTypeEnum;
  newSim?: boolean | undefined;
  /** Null means hide recharge entirely. This is different from undefined, which indicates new eSIM */
  recharge?: {
    iccid: string;
    simLabel: string;
  } | null;

  textVariant?: TypographyVariantType;
  textColor?: keyof typeof colors;
  // Recharge is foreshortened
  small?: boolean;
  // Text should be truncated. Specify a max width if you need fixed heights.
  maxWidth?: number;
}

export function EsimPlanSummaryLabel(props: EsimPlanSummaryLabelProps) {
  const {
    validityDays,
    quotaInGb,
    recharge,
    dataType,
    textVariant = 'body2Regular',
    textColor,
    maxWidth,
    small,
  } = props;
  const shouldTruncate = maxWidth != null;
  const { t } = useTranslation();
  const temp: string[] = [];

  if (validityDays != null) {
    temp.push(t('common:days_extra', { extra: validityDays }));
  }

  if (quotaInGb != null) {
    temp.push(
      `${string2number(quotaInGb)} GB` +
        (dataType === DataPlanTypeEnum.Unlimited
          ? ` ${t('enum:data_plan_type.unlimited')}`
          : ''),
    );
  }

  // Null means hide recharge
  if (recharge !== null) {
    // https://app.asana.com/0/1206502928788502/1208130835143392/f
    const rechargeEsim = !!recharge?.iccid || props.newSim === false;
    let rechargeLabel: string;
    if (!recharge?.simLabel) {
      rechargeLabel = t('sim:recharge_esim');
    } else {
      // This is to handle cases when the label becomes too long.
      if (small) {
        rechargeLabel = recharge?.simLabel;
      } else {
        rechargeLabel = t('common:recharge_extra', {
          extra: recharge?.simLabel,
        });
      }
    }
    temp.push(rechargeEsim ? rechargeLabel : t('common:new_esim'));
  }

  const text = temp.join(' | ');

  return (
    <Text
      textVariant={textVariant}
      textColor={textColor}
      ta="left"
      wrap={!shouldTruncate}
      overflow={shouldTruncate ? 'ellipsis' : undefined}
      maw={maxWidth}
    >
      {text}
    </Text>
  );
}

interface EsimPlanFlagComponentProps {
  scale: ProductScaleEnum;
  areaCode: string;
  type: DataPlanTypeEnum | undefined;

  estimatedExpiration?: Date | null;
  isSubscribed?: boolean;
  isActivated?: boolean;
  quota?: {
    remaining: number;
    limit: number;
  };
}

export function EsimPlanFlagComponent(props: EsimPlanFlagComponentProps) {
  const {
    type,
    scale,
    areaCode,
    isActivated,
    estimatedExpiration,
    isSubscribed: subscription,
    quota,
  } = props;

  const isLowData =
    (quota ? quota.remaining / quota.limit < 0.2 : false) &&
    type === DataPlanTypeEnum.Limited;
  const isNoData = quota?.remaining === 0 && type === DataPlanTypeEnum.Limited;

  const isExpiringDays =
    estimatedExpiration != null &&
    Math.abs(
      differenceInDays(endOfDay(estimatedExpiration), endOfDay(new Date())),
    ) <= 2;
  const isExpiring = isActivated && isExpiringDays;
  const isSubscribed = subscription;
  const showStatus = isSubscribed || isExpiring || isLowData || isNoData;
  const { t } = useTranslation();

  let label: string | undefined, iconColor: string | undefined;
  if (isSubscribed) {
    label = t('sim:plan.auto_recharge.tooltip');
    iconColor = colors.mainBlue;
  } else if (isNoData) {
    label = t('sim:plan.no_data.tooltip');
    iconColor = colors.sentimentWarning;
  } else if (isLowData) {
    label = t('sim:plan.low_data.tooltip');
    iconColor = colors.sentimentWarning;
  } else if (isExpiring) {
    label = t('sim:plan.expiring.tooltip');
    iconColor = colors.sentimentWarning;
  } else {
    label = undefined;
    iconColor = undefined;
  }

  return (
    <Tooltip label={label} disabled={!showStatus}>
      <div
        className={EsimPlanComponentStyles.statusContainer}
        onClick={interceptClickEvents}
      >
        <ESIMComponent scale={scale} areaCode={areaCode} />
        {showStatus && (
          <div
            className={EsimPlanComponentStyles.status}
            style={{
              backgroundColor: iconColor,
            }}
          >
            {isSubscribed ? (
              <ClockCounterClockwise color={colors.mainWhite} />
            ) : isExpiring || isLowData || isNoData ? (
              <ExclamationMark color={colors.mainWhite} />
            ) : undefined}
          </div>
        )}
      </div>
    </Tooltip>
  );
}

interface EsimStateMessageProps {
  activationAt: Date | null;
  startAt: Date | null;
  endAt: Date | null;
  status: SimPlanStatusEnum;
  validityDays: number;
  estimatedExpiration: Date | null;
}

function EsimUsageStateMessage(plan: EsimStateMessageProps) {
  /*
  Dari ko Hendry

  description by status:
  pending (mostly di upcoming plans)
    Activation on (activation_at)
    Aktivasi pada (activation_at)
  inactive (mostly di active plans)
    Valid until (estimated_expiration)
    Valid hingga (estimated_expiration)
  active (mostly di active plans)
    kalau dalam sudah dekat (3 hari terakhir dengan patokan pada estimated_expiration),
    kasih dalam rincian waktu, paling mentok di level jam
    contoh:
    Expires in 2 Days 23 Hours, Expires in 1 Day 1 Hour, Expires in 8 Hours, Expires in 1 Hour
    Kedaluwarsa dalam 2 Hari 23 Jam, Kedaluwarsa dalam 1 Hari 1 Jam, Kedaluwarsa dalam 8 Jam, Kedaluwarsa dalam 1 Jam
    ------
    Selain itu pake
    Valid until (estimated_expiration)
    Valid hingga (estimated_expiration)
  deactivated
    sama seperti active, terjadi karena quota dah habis
  */

  const { t } = useTranslation();

  if (plan.status === SimPlanStatusEnum.Pending) {
    return t('sim:activation_on', {
      extra:
        plan.activationAt || plan.startAt
          ? formatDate((plan.activationAt ?? plan.startAt)!)
          : '-',
    });
  }

  const validUntilMessage = t('sim:valid_until_extra', {
    extra: plan.estimatedExpiration
      ? formatDate(plan.estimatedExpiration)
      : '-',
  });
  if (plan.status === SimPlanStatusEnum.Inactive) {
    return validUntilMessage;
  }

  // Jika plan adalah active atau deactivated
  const isExpiringSoon =
    plan.estimatedExpiration != null &&
    differenceInDays(
      endOfDay(plan.estimatedExpiration),
      endOfDay(new Date()),
    ) <= 3;

  if (isExpiringSoon) {
    return t('sim:expires_in_extra', {
      extra: plan.estimatedExpiration
        ? formatDateDifference(new Date(), plan.estimatedExpiration, t)
        : '-',
    });
  } else {
    return validUntilMessage;
  }
}

interface EsimPlanUsageComponentProps extends EsimStateMessageProps {
  remaining: number;
  limit: number;
  type: DataPlanTypeEnum;
  activatedAt: Date | null;
}

export function EsimPlanUsageComponent(props: EsimPlanUsageComponentProps) {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={classNames(
          structuralStyles.text({ align: 'left' }),
          structuralStyles.flexbox({ gap: 4 }),
        )}
      >
        <Text>
          <Text
            textVariant="h4"
            span
          >{`${toLoosePrecision(props.remaining, 2)} GB `}</Text>
          <Text
            textVariant="body2Regular"
            span
          >{`/ ${toLoosePrecision(props.limit, 2)} GB`}</Text>
          {props.type === DataPlanTypeEnum.Unlimited && (
            <Text textVariant="body2Regular" span>
              {` ${capitalize(t('sim:unlimited'))}`}
            </Text>
          )}
        </Text>
        {props.type === DataPlanTypeEnum.Unlimited && (
          <>
            {props.activatedAt != null && (
              <TooltipIcon
                label={t('sim:plan.fup_reset_time.tooltip', {
                  extra: `${format(props.activatedAt, 'HH:mm', {
                    locale: getDateLocale(),
                  })} (${formatTimezone(props.activatedAt)})`,
                })}
              >
                <InfoIcon color={colors.foregroundTertiary} />
              </TooltipIcon>
            )}
          </>
        )}
      </div>
      <Text textVariant="body2Regular" ta="left">
        <EsimUsageStateMessage
          activationAt={props.activationAt}
          endAt={props.endAt}
          estimatedExpiration={props.estimatedExpiration}
          startAt={props.startAt}
          status={props.status}
          validityDays={props.validityDays}
        />
      </Text>
    </>
  );
}

interface EsimPlanComponentProps {
  /* Plan info */
  name: string;
  grade: ProductItemGradeEnum;

  Flag?: React.ReactNode;
  Right?: React.ReactNode;
  Below?: React.ReactNode;

  customization?: {
    root?: CustomizationUnit<React.ComponentProps<'div'>>;
    body?: {
      root?: CustomizationUnit<React.ComponentProps<'div'>>;
      title?: CustomizationUnit<TextProps>;
      layout?: CustomizationUnit<React.ComponentProps<'div'>>;
    };
  };
}

function EsimPlanComponent(props: EsimPlanComponentProps) {
  const { Flag, name, Right, Below, grade, customization } = props;
  const { t } = useTranslation();

  return (
    <div
      {...applyCustomization(
        {
          className: structuralStyles.fill({ width: true }),
        },
        [customization?.root],
      )}
    >
      <div
        {...applyCustomization(
          {
            className: structuralStyles.flexbox({
              direction: 'row',
              align: 'start',
              gap: 16,
            }),
          },
          [customization?.body?.root],
        )}
      >
        {Flag}
        <div
          {...applyCustomization(
            {
              className: structuralStyles.flexbox({
                direction: 'column',
                align: 'stretch',
                fill: true,
                gap: 4,
              }),
            },
            [customization?.body?.layout],
          )}
        >
          {/* Title */}
          <div
            className={classNames(
              structuralStyles.flexbox({ gap: 4 }),
              structuralStyles.fill({ width: true }),
            )}
          >
            <Text
              {...applyCustomization(
                {
                  textVariant: 'body1Semibold',
                  ta: 'left',
                },
                [customization?.body?.title],
              )}
            >
              {name}
            </Text>
            {grade === ProductItemGradeEnum.Premium && (
              <TooltipIcon label={t('sim:plan.premium.tooltip')}>
                <PremiumPlanIcon />
              </TooltipIcon>
            )}
          </div>
          {Right}
        </div>
      </div>
      {Below}
    </div>
  );
}

(EsimPlanComponent as any).Label = EsimPlanSummaryLabel;
(EsimPlanComponent as any).Price = EsimPlanPriceComponent;
(EsimPlanComponent as any).Flag = EsimPlanFlagComponent;
(EsimPlanComponent as any).Usage = EsimPlanUsageComponent;

export default EsimPlanComponent as typeof EsimPlanComponent & {
  Label: typeof EsimPlanSummaryLabel;
  Price: typeof EsimPlanPriceComponent;
  Flag: typeof EsimPlanFlagComponent;
  Usage: typeof EsimPlanUsageComponent;
};
