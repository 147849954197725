import { SimPlanModel } from 'api-hooks/sim/model';
import classNames from 'classnames';
import { CustomValue } from 'common/repositories/customization';
import { formatDate } from 'common/utils/date';
import { string2money } from 'common/utils/string';
import Text from 'components/elements/text/base';
import EsimPlanComponent from 'modules/esim/components/item';
import React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

interface EsimPlanItemComponentProps {
  data: SimPlanModel;
}

export function ActiveEsimPlanComponent(props: EsimPlanItemComponentProps) {
  const { data } = props;
  return (
    <EsimPlanComponent
      name={data.name}
      grade={data.grade}
      customization={{
        body: {
          layout: {
            className: CustomValue.overwrite(
              structuralStyles.flexbox({
                direction: 'column',
                align: 'stretch',
                fill: true,
              }),
            ),
          },
        },
      }}
      Flag={
        <EsimPlanComponent.Flag
          type={data.type}
          areaCode={data.areaCode}
          scale={data.scale}
          estimatedExpiration={data.estimatedExpiration}
          isSubscribed={!!data.subscription}
          isActivated={data.activatedAt != null}
          quota={{
            remaining: data.quotaRemaining,
            limit: data.quotaLimit,
          }}
        />
      }
      Right={
        <EsimPlanComponent.Usage
          activationAt={data.activationAt}
          activatedAt={data.activatedAt}
          endAt={data.endAt}
          estimatedExpiration={data.estimatedExpiration}
          limit={data.quotaLimit}
          remaining={data.quotaRemaining}
          startAt={data.startAt}
          status={data.status}
          type={data.type}
          validityDays={data.validityDays}
        />
      }
    />
  );
}

export function UpcomingEsimPlanComponent(props: EsimPlanItemComponentProps) {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <EsimPlanComponent
      name={data.name}
      grade={data.grade}
      Flag={
        <EsimPlanComponent.Flag
          areaCode={data.areaCode}
          scale={data.scale}
          type={data.type}
        />
      }
      Right={
        <>
          <EsimPlanComponent.Label
            dataType={data.type}
            quotaInGb={data.quotaLimit}
            validityDays={data.validityDays}
            recharge={null}
          />
          <Text textVariant="body2Regular" ta="left">
            {t('sim:activation_on', {
              extra:
                data.activationAt || data.startAt
                  ? formatDate(data.activationAt ?? data.startAt!)
                  : '-',
            })}
          </Text>
        </>
      }
    />
  );
}

export function PastEsimPlanComponent(props: EsimPlanItemComponentProps) {
  const { data } = props;
  const { t } = useTranslation();
  return (
    <EsimPlanComponent
      name={data.name}
      grade={data.grade}
      Flag={
        <EsimPlanComponent.Flag
          areaCode={data.areaCode}
          scale={data.scale}
          type={data.type}
        />
      }
      Right={
        <>
          <EsimPlanComponent.Label
            dataType={data.type}
            quotaInGb={data.quotaLimit}
            validityDays={data.validityDays}
            recharge={null}
          />
          <div className={structuralStyles.fill({ width: true })}>
            {!!data?.activatedAt && (
              <Text textVariant="body2Regular" py={4} ta="left">
                {t('sim:activated_on', {
                  extra: formatDate(data.activatedAt),
                })}
              </Text>
            )}
            <div
              className={classNames(
                structuralStyles.flexbox({
                  direction: 'row',
                  justify: 'between',
                  fill: true,
                }),
                structuralStyles.fill({ width: true }),
              )}
            >
              <Text textVariant="body2Regular" textColor="foregroundTertiary">
                {t('common:price')}
              </Text>
              <Text textVariant="body2Semibold">{`IDR ${string2money(data.price)}`}</Text>
            </div>
          </div>
        </>
      }
    />
  );
}
